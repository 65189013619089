.timer-wrapper {
  position: fixed;
  top: 0;
  right: 10%;
  padding: 2rem 7rem 6rem;
  background-image: url(/public/images/timerHomeBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.timer-wrapper.home-timer {
  width: 221px;
  height: 331px;
}

.timer-wrapper h2 {
  font-size: 160px;
  line-height: 160px;
}

.timer-wrapper.home-timer h2,
.timer-wrapper.home-timer h4 {
  text-align: center;
}

.timer-wrapper h4 {
  font-size: 92.8px;
  line-height: 92.8px;
}

.object-for-wash {
  position: absolute;
  top: 1109px;
  z-index: 3;
}

.liquid-detergent-wrapper {
  left: 50%;
  transform: translateX(-33%) translateY(-3%);
}

.machine-position-home {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -704px);
  width: 890px;
}

.machine-position-home.close-lid-position {
  transform: translate(-50.5%, -715px);
  width: 883px;
}

.close-lid-machine {
  position: absolute;
  left: 50%;
  transform: translate(-49.1%, -558px);
  width: 2050px;
}

.clean-shirt {
  top: 548px;
}

.clean-shirt-png {
  top: 642px;
}

.spark-behind-shirt-img {
  top: 400px;
  width: 100%;
  z-index: 1;
}

.patel-behind-shirt {
  top: -100px;
}

.headline-clean-shirt {
  top: 0;
}

.creative-text {
  top: 30%;
  right: 10%;
}

.washing-timer-home {
  position: absolute;
  bottom: 1200px;
  right: 250px;
  transform: scale(2);
}
